







































export default {
  name: 'ConfirmationDialog',
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      options: {
        cancel: 'Cancel',
        confirm: 'OK',
        color: 'red lighten-1',
        width: 300,
        zIndex: 200,
        noconfirm: false,
      },
    };
  },

  methods: {
    open(
      title: string,
      message: string,
      options: object,
    ): Promise<unknown> {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};
